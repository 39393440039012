<template>
  <div ref="opt_comm" class="opt_comm" :class="classList" @click.prevent="openToggle">
    <!-- 비활성일 때 disabled 클래스 추가 / 100px 너비 타입은 type_short 클래스 추가 / 읽음 표시 타입일 때 type_readcheck 클래스 추가 -->
    <strong class="screen_out">{{ optionDesc }}</strong
    ><!-- 내용에 따라 다르게 입력 -->
    <span class="screen_out">선택내용 : </span>
    <a ref="aria" href="javascript:void(0);" class="link_opt" aria-expanded="false"
      ><!-- 오픈시 true, 닫혔을 때 false -->
      {{ optionName }}<span class="ico_account ico_opt" />
    </a>
    <div class="box_opt">
      <span class="screen_out">선택옵션</span>
      <ul class="list_opt" role="listbox">
        <li
          v-for="(item, index) in optionList"
          :key="index"
          :class="'item_depth' + item.deptDepth"
          @click.prevent="selectOption(item)"
        >
          <a
            href="javascript:void(0);"
            class="link_option"
            role="option"
            :aria-selected="[item.deptName === optionName ? 'true' : 'false']"
          >
            {{ item.deptName }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CommOptDept",
  props: {
    classList: Array,
    optionName: String,
    optionDesc: String,
    eventName: String,
    validate: Function,
  },
  data() {
    return {
      option: {},
      optionList: [
        {
          deptName: "전체",
          deptCode: "",
        },
      ],
    };
  },
  created() {
    axios
      .post("/api/common/selectAllDepartmentList")
      .then((res) => {
        res.data.forEach((data) => {
          this.optionList.push(data);
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  },
  methods: {
    openToggle() {
      if (this.$refs.aria.getAttribute("aria-expanded") === "true") {
        this.$refs.aria.setAttribute("aria-expanded", "false");
        this.$refs.opt_comm.classList.remove("opt_open");
      } else {
        let optOpenArr = document.querySelectorAll(".opt_open");
        for (let i = 0, leng = optOpenArr.length; i < leng; i++) {
          optOpenArr[i].classList.remove("opt_open");
          optOpenArr[i].querySelector(".link_opt").setAttribute("aria-expanded", "false");
        }
        this.$refs.aria.setAttribute("aria-expanded", "true");
        this.$refs.opt_comm.classList.add("opt_open");
      }
    },
    selectOption(option) {
      if (this.validate) {
        if (this.validate(option)) {
          this.option = option;
          this.optionName = this.option.deptName;
          this.$emit(this.eventName, option);
        }
      } else {
        this.option = option;
        this.optionName = this.option.deptName;
        this.$emit(this.eventName, option);
      }
      this.$refs.opt_comm.classList.remove("error");
    },
  },
};
</script>
