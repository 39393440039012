<template>
  <div class="box_sorting">
    <strong class="screen_out">문서 검색하기</strong>
    <ul class="list_sorting">
      <li v-if="!isApproval || isDetailPage" :class="['item_sort']">
        <!-- 결재/참조 문서가 아니거나, 상세 검색이 열려있거나 -->
        <em class="tit_txt">{{ titleDate }}</em>
        <div class="cont_sorting">
          <div class="box_picker">
            <DatePicker :date.sync="searchForm.draftFromDate" />

            <span class="txt_bar">~</span>
            <DatePicker :date.sync="searchForm.draftToDate" />
          </div>
        </div>
      </li>
      <li v-if="(!isApproval && isComplete) || (isDetailPage && isComplete)" class="item_sort">
        <em class="tit_txt">{{ titleComplete }}</em>
        <div class="cont_sorting">
          <!-- 달력 입력 -->
          <div class="box_picker">
            <DatePicker :date.sync="searchForm.completeFromDate" />

            <span class="txt_bar">~</span>
            <DatePicker :date.sync="searchForm.completeToDate" />
          </div>
        </div>
      </li>

      <li class="item_full">
        <em class="tit_txt">검색어</em>
        <!-- {{titleSearch}} -->
        <div class="cont_sorting">
          <!-- 검색(sort) -->
          <div class="group_search">
            <!-- opt_comm -->
            <Radio
              v-if="isSearchTypeRadio"
              :dataList="searchTypeRadioList ? searchTypeRadioList : SEARCH_TYPE_RADIO_LIST"
              name="radioFilterbar"
              :selectedId.sync="searchForm.searchType.code"
              @onChange="selectSearchType"
            />
            <comm-opt
              v-else
              class="w208"
              :classList="['type_short']"
              :optionDesc="'검색 선택상자'"
              :optionName="searchForm.searchType.name"
              :optionList="
                searchTypeSelectBoxList || searchSelectOptions || SEARCH_TYPE_SELECTBOX_LIST
              "
              :eventName="'select'"
              @select="selectSearchType"
            />
            <!-- opt_comm -->
            <div class="group_input">
              <Input
                :value.sync="searchForm.searchKeyword"
                :maxLength="100"
                @onEnterKey="keypress"
              />
            </div>
            <!-- // group_input -->
          </div>
          <!-- // group_search -->
        </div>
      </li>
    </ul>
    <!-- // list_sorting -->
    <div class="wrap_btn">
      <div v-if="isDetailPage" class="pos_left"></div>
      <button type="button" class="btn_large btn_primary" @click.prevent="clickSearchButton">
        검색
      </button>
      <a href="javascript:void(0);" class="link_reset" @click.prevent="resetSearchForm">
        <span class="ico_account" />초기화
      </a>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CommOpt from "@/_approval/components/common/CommOpt";
import CommOptDept from "@/_approval/components/common/CommOptDept";
import CommInpSearch from "@/_approval/components/common/CommInpSearch";
import CommOptDeptSearch from "@/_approval/components/common/CommOptDeptSearch";
import CommOptSearch from "@/_approval/components/common/CommOptSearch";
import Radio from "@/_approval/components/common/radio/Radio";
import Input from "@/_approval/components/common/input/Input";
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
import { default as CommFlatpickrMixin } from "@/_approval/mixins/common/commFlatpickr.js";
import { default as CommMomentMixin } from "@/_approval/mixins/common/commMoment.js";

import DatePicker from "@/components/common/calendar/DatePicker";

// 초기 값
const initSearchForm = {
  payAccountName: "계정 선택",
  draftDeptName: "부서 선택",
  deptName: "Cost Center 선택",
  payAccountList: [],
  draftFlag: "",
  selectedPayAccount: {},
  // 구매요청, 정산서 모두 똑같은 스타일이라면. 검색어 정보는 밖에서 받는 걸로.
  searchType: { code: "docNo", name: "문서번호" },
  searchTypeList: [
    { code: "docNo", name: "문서구분" },
    { code: "menuId", name: "구분" },
    { code: "drftEmpName", name: "기안요청자" },
    { code: "regDate", name: "저장일시" },
  ],
  selectedEmp: "",
  draftDeptCode: "",
  deptCode: "",
  searchKeyword: "",
  draftFromDate: null,
  draftToDate: null,
  completeFromDate: null,
  completeToDate: null,
  docState: "AR",
  flatpickrConfigDayDraftFrom: {},
  flatpickrConfigDayDraftTo: {},
  flatpickrConfigDayCompleteFrom: {},
  flatpickrConfigDayCompleteTo: {},
};

export default {
  name: "SearchDiv",
  components: {
    DatePicker,
    CommOpt,
    CommInpSearch,
    CommOptDept,
    CommOptDeptSearch,
    CommOptSearch,
    Radio,
    Input,
  },
  mixins: [CommFlatpickrMixin, CommLayerMixin, CommMomentMixin],
  props: {
    titleSearch: String,
    titleDate: String,
    titleComplete: String,
    draftEmpNo: [String, Number],
    // openSearchBox: Boolean, // 검색박스 노출 여부 -> kep에는 다 존재하는 듯?
    isDetailPage: Boolean, // 상세검색 허용 페이지인가 여부
    isApproval: Boolean, // 결재문서 또는 참조 문서함인 경우 true
    isComplete: Boolean, // 결재완료 (승인/반려) 문서함인 경우 true
    isCc: Boolean, // 참조문서함
    isSearchTypeRadio: Boolean, // 검색어 선택 유형 2가지만 있다고 고려. (SEARCH_TYPE_RADIO_LIST/SEARCH_TYPE_SELECTBOX_LIST)
    searchSelectOptions: Array,
    searchTypeRadioList: Array,
    searchTypeSelectBoxList: Array,
    searchType: Object,
  },
  data() {
    return {
      searchForm: { ...initSearchForm },
      isDetailOpen: false, // 상세검색 오픈/클로즈
      isCostCenter: false, //costCenter 무조건 안보이게 처리  To-do: 추후 필요시 삭제
      SEARCH_TYPE_RADIO_LIST: [
        { code: "docNo", name: "문서구분" },
        { code: "menuId", name: "구분" },
        { code: "drftEmpName", name: "기안요청자" },
      ],
      SEARCH_TYPE_SELECTBOX_LIST: [
        { code: "docNo", name: "문서번호" },
        { code: "docTitle", name: "제목" },
        { code: "menuId", name: "구분" },
        { code: "nextAprvl", name: "결재대기자" },
      ],
      draftStartDateDisabled: false,
      draftEndDateDisabled: false,
      approvalStartDateDisabled: false,
      approvalEndDateDisabled: false,
    };
  },
  watch: {},
  created() {
    this.flatpickrConfigDayDraftFrom = { ...this.flatpickrConfigDay };
    this.flatpickrConfigDayDraftTo = { ...this.flatpickrConfigDay };
    this.flatpickrConfigDayCompleteFrom = { ...this.flatpickrConfigDay };
    this.flatpickrConfigDayCompleteTo = { ...this.flatpickrConfigDay };
    // 찾는 폼이 비어있지 않는 경우 init을 서칭 폼으로 매핑
    if (localStorage.searchForm) {
      this.searchForm = JSON.parse(localStorage.searchForm);
    }
  },

  mounted() {
    if (this.searchType) {
      this.searchForm.searchType = this.searchType;
    }
    // searchForm 초기화
    this.$emit("init-search-form", this.searchForm);
  },
  methods: {
    listenToOnChangeDay(dObj, dStr, fp, dayElem) {
      // dObj는 range date의 시작일, 종료일이 배열로 저장되어 있음
    },
    listenToOnCloseDay(dObj, dStr, fp, dayElem) {},
    selectPayAccount(payAccount) {
      this.searchForm.payAccountName = payAccount.name;
      this.searchForm.payAccountId = payAccount.code;
      this.searchForm.selectedPayAccount = payAccount;
      this.$emit("select-connector", this.searchForm); // 부모 함수(selectAccount)에게 넘김
    },
    selectDraftDeptCode(draftDept) {
      // console.log(draftDept)
      this.searchForm.draftDeptCode = draftDept.deptCode;
      this.searchForm.draftDeptName = draftDept.deptName;
      this.$emit("select-connector", this.searchForm);
    },
    selectDeptCode(dept) {
      this.searchForm.deptCode = dept.deptCode;
      this.searchForm.deptName = dept.deptName;
      this.$emit("select-connector", this.searchForm);
    },
    selectSearchType(searchType) {
      this.draftStartDateDisabled = searchType.code === "docCid";
      this.draftEndDateDisabled = searchType.code === "docCid";
      this.approvalStartDateDisabled = searchType.code === "docCid";
      this.approvalEndDateDisabled = searchType.code === "docCid";

      if (searchType.code === "docCid") {
        this.searchForm.draftToDate = "";
        this.searchForm.draftFromDate = "";
        this.searchForm.completeFromDate = "";
        this.searchForm.completeToDate = "";
      }

      this.searchForm.searchType = searchType;
    },
    toggleSearchDetail() {
      if (this.isDetailPage) {
        this.isDetailOpen = !this.isDetailOpen; // 상세 검색 오픈/클로징 처리
      }
    },
    clickSearchButton() {
      this.$emit("select-connector", this.searchForm); // 부모 함수(selectDocList)에게 넘김
    },
    resetSearchForm() {
      this.searchForm = { ...initSearchForm };

      // initSearchForm 의 개념이 모호.
      // this.searchForm.searchType = { code: 'subject', name: '구매요청명' };
    },
    keypress(key) {
      if (key.which === 13) {
        this.$emit("select-connector", this.searchForm);
      }
    },
    setPayAccountList(payAccountList) {
      this.searchForm.payAccountList = [];
      const list = this.searchForm.payAccountList;
      list.push({ name: "전체", code: "" });
      payAccountList.forEach(function (value) {
        list.push({
          name: value.payAccountName,
          code: value.payAccountId,
        });
      });
      initSearchForm.payAccountList = list;
    },
    attachEmployee(emp) {
      this.searchForm.draftEmpNo = emp.empNo;
      this.searchForm.selectedEmp = emp.empName + "(" + emp.accountId + ")";
    },
    searchOrganization() {
      let layer = {};
      layer.type = "popup";
      layer.class = "inner_structure_layer";
      layer.header = "조직도";
      layer.componentFile = "common/CommPopOrganization.vue";
      layer.props = {
        orgType: 1, // 1:single select, 2: multi select
        caution: "열람자 추가시 선택된 열람자에게 ‘문서열람 알림’이 발송됩니다.",
      };
      this._showLayer(layer, this.attachEmployee);
    },
    initDraftEmp() {
      this.searchForm.draftEmpNo = "";
    },
    displaySearchResult(dataList) {
      dataList.forEach((value) => {
        value.display = value.empName + "(" + value.accountId + ")";
      });
    },
    listenToOnChangeDayDraftFrom(dObj, dStr, fp, dayElem) {
      // dObj는 range date의 시작일, 종료일이 배열로 저장되어 있음
      this.flatpickrConfigDayDraftTo.minDate = dStr;
      this.flatpickrConfigDayDraftTo = { ...this.flatpickrConfigDayDraftTo };

      // this.calculateDateEnd = null;
    },
    listenToOnChangeDayCompleteFrom(dObj, dStr, fp, dayElem) {
      // dObj는 range date의 시작일, 종료일이 배열로 저장되어 있음
      this.flatpickrConfigDayCompleteTo.minDate = dStr;
      this.flatpickrConfigDayCompleteTo = {
        ...this.flatpickrConfigDayCompleteTo,
      };
    },
  },
};
</script>
